import React, { createContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';

type DynamicVaccineQuestion = {
  questionName: string;
  fieldsToRender: number;
  vaccineName: string;
};

type SelectedDynamicVaccinesQuestionType = {
  selectedDynamicQuestions: DynamicVaccineQuestion[];
  setSelectedDynamicQuestions: Dispatch<SetStateAction<DynamicVaccineQuestion[]>>;
};

const defaultValue = {
  selectedDynamicQuestions: [],
  setSelectedDynamicQuestions: () => {},
};

export const SelectedDynamicVaccinesQuestionsCtx = createContext<SelectedDynamicVaccinesQuestionType>(defaultValue);

export default function SelectedDynamicVaccinesQuestionsContext({ children }: { children: ReactNode }) {
  const [selectedDynamicQuestions, setSelectedDynamicQuestions] = useState(defaultValue.selectedDynamicQuestions);

  return (
    <SelectedDynamicVaccinesQuestionsCtx.Provider
      value={{
        selectedDynamicQuestions,
        setSelectedDynamicQuestions: setSelectedDynamicQuestions as Dispatch<SetStateAction<DynamicVaccineQuestion[]>>,
      }}
    >
      {children}
    </SelectedDynamicVaccinesQuestionsCtx.Provider>
  );
}
