/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import type { Theme } from 'src/theme';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  PlusSquare as AddIcon,
  Monitor as OperationIcon,
  BarChart as MetricsOneIcon,
  BarChart2 as MetricsTwoIcon,
  Settings as SettingsIcon,
  Activity as ActivityIcon,
  FileText as FileIcon,
  UserCheck as UserCheckIcon,
  PhoneIncoming as ContactIcon
} from 'react-feather';
import NavItem from './NavItem';
import { useTranslation } from 'react-i18next';
import { AccountBox } from '@material-ui/icons';
import useData from 'src/hooks/useData';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

function renderNavItems({ items, pathname, depth = 0 }: { items: Item[]; pathname: string; depth?: number }) {
  return (
    <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  logoContainer: {
    background: theme.palette.background.logo,
    padding: '.6rem',
    borderRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px',
  },
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();

  const { protocols, enterprises, contactTicketsSubject } = useData();

  const sections: Section[] = [
    {
      subheader: 'NAVBAR_MANAGEMENT_TEXT',
      items: [
        {
          title: 'NAVBAR_MANAGEMENT_VERIFICATION',
          icon: OperationIcon,
          items: [
            {
              title: "NAVBAR_MANAGEMENT_PASSE_VERDE_TEXT",
              icon: AccountBox,
              items: [
                ...protocols.map((proto) => ({
                  title: proto.name,
                  href: `/app/management/protocol/${proto._id}`
                })),
                {
                  title: 'NAVBAR_MANAGEMENT_TOURIST_LIST_NORONHA',
                  href: '/app/management/users-list',
                },
                {
                  title: 'NAVBAR_MANAGEMENT_TOURIST_LIST_SHOW',
                  href: '/app/management/show-list',
                },
                {
                  title: 'NAVBAR_MANAGEMENT_SEARCH',
                  href: '/app/management/search',
                },
              ]
            },
            {
              title: "NAVBAR_MANAGEMENT_SICK_NOTES",
              icon: ActivityIcon,
              items: [
                ...enterprises.map((enterprise) => ({
                  title: enterprise.name,
                  href: `/app/management/enterprise/${enterprise._id}`,
                })),
              ]
            },
            {
              title: "NAVBAR_MANAGEMENT_VACCINATION_CARD_REGISTRIES",
              icon: FileIcon,
              href: `/app/management/vcr`,
            },
            {
              title: "NAVBAR_MANAGEMENT_HCPS",
              icon: UserCheckIcon,
              href: `/app/management/hcps`,
            },
            {
              title: "NAVBAR_MANAGEMENT_ENTERPRISE_OPERATORS",
              icon: UserCheckIcon,
              href: `/app/management/enterprise-operators`,
            },
            {
              title: "Vacinas (novas clínicas)",
              icon: UserCheckIcon,
              href: '/app/management/clinic-vaccines',
            },
          ],
        },
        {
          title: "NAVBAR_MANAGEMENT_CONTACT_TICKETS",
          icon: ContactIcon,
          items: [
            ...contactTicketsSubject.map((contactTicket) => ({
              title: t(contactTicket),
              href: `/app/management/contact-tickets/${contactTicket.toLowerCase()}`,
            })),
          ].sort((a, b) => {
            return a.title > b.title ? 1 : a.title < b.title ? -1 : 0
          })
        },
        {
          title: 'NAVBAR_MANAGEMENT_SETTINGS',
          icon: SettingsIcon,
          items: [
            {
              title: 'NAVBAR_MANAGEMENT_SETTINGS_WORK_SCHEDULE',
              href: '/app/settings/work-schedule',
            },
            {
              title: 'NAVBAR_MANAGEMENT_SETTINGS_ADD_ORGANIZER',
              href: '/app/settings/add-organizer',
            },
            {
              title: 'NAVBAR_MANAGEMENT_SETTINGS_ADD_EVENT',
              href: '/app/settings/add-events',
            },
          ],
        },
        {
          title: 'Gestão',
          icon: AddIcon,
          items: [
            {
              title: 'Clínica',
              href: '/app/add/clinic',
            },
            {
              title: 'Operadores de clínicas',
              href: '/app/add/clinic-operator',
            },
            {
              title: 'Plano',
              href: '/app/add/plan',
            },
            {
              title: 'NAVBAR_MANAGEMENT_ADD_ENTERPRISE',
              href: '/app/add/enterprise',
            },
          ]
        },
        {
          title: 'NAVBAR_MANAGEMENT_COMMERCIAL_METRICS',
          href: '/app/metrics/commercial',
          icon: MetricsOneIcon,
        },
        {
          title: 'NAVBAR_MANAGEMENT_OPERATIONAL_METRICS',
          href: '/app/metrics/operational',
          icon: MetricsTwoIcon,
        },
      ],
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box
          height="88px"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          mt={2}
          mb={2}
        >
          <img alt="logo immunie passe verde" width="180px" src="/static/images/logo-passe-verde-h.svg" />
          <Typography style={{ fontWeight: 'bold', fontSize: 'small' }} align="center">
            {t('NAVBAR_VALIDATION')}
          </Typography>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {t(section.subheader)}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              {t('NAVBAR_NEED_HELP_TEXT')}
            </Typography>
            <Link variant="subtitle1" color="secondary" component={RouterLink} to="/app/support">
              {t('NAVBAR_CHECK_DOCS')}
            </Link>
          </Box>
          <Typography style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>
            Version: 2.0.2
          </Typography>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
