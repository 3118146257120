const apiConfig =
  process.env.NODE_ENV === 'development'
    ? { coreApiBaseUrl: process.env.REACT_APP_CORE_API_DEV, hashApiBaseUrl: process.env.REACT_APP_HASH_API_DEV }
    : { coreApiBaseUrl: process.env.REACT_APP_CORE_API, hashApiBaseUrl: process.env.REACT_APP_HASH_API };

const PERMISSIONS = {
  PROFILE_PHOTO: "PROFILE_PICTURE_ACCESS",
  CREATE_CLINIC: "CREATE_CLINIC",
  FULL_DOCUMENT_NUMBER: "FULL_DOCUMENT_NUMBER_ACCESS",
  CREATE_EVENTS_AND_ORGANIZERS: "CREATE_EVENTS_AND_ORGANIZERS",
  UPDATE_PASSE_VERDE_LV3: "UPDATE_PASSE_VERDE_LV3",
  UPDATE_PASSE_VERDE_LV2: "UPDATE_PASSE_VERDE_LV2",
  UPDATE_PASSE_VERDE_LV1: "UPDATE_PASSE_VERDE_LV1",
  COMMERCIAL_METRICS_ACCESS: "COMMERCIAL_METRICS_ACCESS",
  OPERATIONAL_METRICS_ACCESS: "OPERATIONAL_METRICS_ACCESS",
  UPDATE_OPERATOR_WORK_SCHEDULE: "UPDATE_OPERATOR_WORK_SCHEDULE",
  EDIT_PROFILE_DATA_LV1: "EDIT_PROFILE_DATA_LV1",
  EDIT_PROFILE_DATA_LV2: "EDIT_PROFILE_DATA_LV2",
};

const PERMISSIONS_TO_EDIT_USER = [
  PERMISSIONS.EDIT_PROFILE_DATA_LV1,
  PERMISSIONS.EDIT_PROFILE_DATA_LV2
];

const PERMISSIONS_TO_UPDATE_PASSE_VERDE = [
  PERMISSIONS.UPDATE_PASSE_VERDE_LV1,
  PERMISSIONS.UPDATE_PASSE_VERDE_LV2,
  PERMISSIONS.UPDATE_PASSE_VERDE_LV3,
];

const RESTRICTION = {
  BR_RESTRICTED_OPERATOR: "BR_RESTRICTED_OPERATOR",
};

const COUNTRY_RESTRICTIONS = [
  RESTRICTION.BR_RESTRICTED_OPERATOR,
];

const RECAPTCHA_KEY = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_RECAPTCHA_KEY_DEV
  : process.env.REACT_APP_RECAPTCHA_KEY;

export {
  apiConfig,
  PERMISSIONS,
  PERMISSIONS_TO_EDIT_USER,
  PERMISSIONS_TO_UPDATE_PASSE_VERDE,
  RESTRICTION,
  COUNTRY_RESTRICTIONS,
  RECAPTCHA_KEY,
};