/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import type { Theme } from 'src/theme';
import { useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Hidden, List, ListSubheader, Typography, makeStyles, Button } from '@material-ui/core';
import { HelpCircle as AnswerIcon, List as ListIcon, BookOpen as ReferencesIcon } from 'react-feather';
import NavItem from './NavItem';
import { useTranslation } from 'react-i18next';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: 'DOC_NAVBAR_TEXT',
    items: [
      {
        title: 'DOC_NAVBAR_PROTOCOLS',
        icon: ListIcon,
        items: [
          {
            title: 'DOC_NAVBAR_PROTOCOLS_NORONHA',
            href: '/documentation/protocols/noronha',
          },
          {
            title: 'DOC_NAVBAR_PROTOCOLS_PERNAMBUCO',
            href: '/documentation/protocols/pernambuco',
          },
          {
            title: 'DOC_NAVBAR_PROTOCOLS_SAO_PAULO',
            href: '/documentation/protocols/sao_paulo',
          },
        ],
      },
      {
        title: 'DOC_NAVBAR_SUGGESTED_ANSWERS',
        icon: AnswerIcon,
        items: [
          {
            title: 'DOC_NAVBAR_SUGGESTED_ANSWERS_REDO',
            href: '/documentation/suggested_answers/redo',
          },
          {
            title: 'DOC_NAVBAR_SUGGESTED_ANSWERS_INCONSISTENT',
            href: '/documentation/suggested_answers/inconsistent',
          }
        ],
      },
      {
        title: 'DOC_NAVBAR_REFERENCES',
        href: '/documentation/references',
        icon: ReferencesIcon,
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }: { items: Item[]; pathname: string; depth?: number }) {
  return (
    <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  logoContainer: {
    background: theme.palette.background.logo,
    padding: '.6rem',
    borderRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px',
  },
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box
          height="88px"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          mt={2}
          mb={2}
        >
          <img alt="logo immunie passe verde" width="180px" src="/static/images/logo-passe-verde-h.svg" />
          <Typography style={{ fontWeight: 'bold', fontSize: 'small' }} align="center">
            {t('NAVBAR_VALIDATION')}
          </Typography>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {t(section.subheader)}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Button href="/">Back</Button>
        <Box p={2}>
          <Typography style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>
           Version: 2.0.2
          </Typography>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
